import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMoment from 'react-moment';
import { Typography, Box, Divider, Grid } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverItem from '../../components/CoverItem';
import LiveStreamSnippet from '../../components/LiveStreamSnippet';
import PlaylistsSnippet from '../../components/PlaylistsSnippet';

const Page = ({ pageContext }) => {
  const { serieses, pageNum, totalPages } = pageContext;

  return (
    <Layout>
      <SEO title="Series" />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Series</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {serieses.map((series) => (
              <Grid item key={series.id} xs={12} md={6}>
                <CoverItem
                  title={series.title}
                  subtext={
                    <>
                      <ReactMoment format="MMMM D">
                        {series.startDate}
                      </ReactMoment>{' '}
                      &mdash;{' '}
                      <ReactMoment format="MMMM D, YYYY">
                        {series.endDate}
                      </ReactMoment>
                    </>
                  }
                  image={series.imageThumb}
                  to={`/media/series/${series.slug}`}
                />
              </Grid>
            ))}
          </Grid>

          <Box mt={6} mb={4}>
            <Pagination
              size="large"
              page={pageNum}
              count={totalPages}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component={Link}
                  to={
                    item.page === 1
                      ? '/media/series'
                      : `/media/series/${item.page}`
                  }
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item md={4} xs={12}>
          <Box mb={8}>
            <LiveStreamSnippet />
          </Box>

          <Box mb={8}>
            <PlaylistsSnippet />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
